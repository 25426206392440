<template>
  <div class="body">
    <pdf ref="pdf" :src="pdfUrl"></pdf>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
export default {
  name: 'Pdf',
  components: {
    pdf
  },
  data () {
    return {
      pdfUrl: "https://invtest.nntest.cn/fp/yTPT0mjfU9wTSduFP_ONaPN-KilafP5L3JLICvIhR7XxWaDA1qIJGWmgPZoDoCgAU5OIDFTqvgS8sh9u0zsrxA.pdf"
    }
  },
  created () {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
</style>
<template>
  <div class="body">
    <pdf ref="pdf" :src="pdfUrl"></pdf>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
export default {
  name: 'Pdf',
  components: {
    pdf
  },
  data () {
    return {
      pdfUrl: "https://invtest.nntest.cn/fp/yTPT0mjfU9wTSduFP_ONaPN-KilafP5L3JLICvIhR7XxWaDA1qIJGWmgPZoDoCgAU5OIDFTqvgS8sh9u0zsrxA.pdf"
    }
  },
  created () {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
</style>
